import React from "react";

const ExitModalButton = ({ closeFunction, ariaLabel, addSVGClassName = "", addClassName = ""}) => {

    return (
        <button 
            aria-label={ariaLabel}
            className={`closeModalButton ${addClassName}`}
            onClick={(e) => {
                e.preventDefault();
                closeFunction();
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" className={`icon-x ${addSVGClassName}`} width="64" height="64" viewBox="0 0 64 64" >
                <path d="M55.19,44.71c-0.87,0.7-1.68,1.13-2.45,1.3c-0.77,0.17-1.61-0.03-2.54-0.59c-0.93-0.56-2.05-1.51-3.37-2.83
                    L36.25,32l10.59-10.59c1.36-1.36,2.5-2.32,3.4-2.86c0.9-0.54,1.75-0.74,2.54-0.59c0.79,0.14,1.6,0.59,2.42,1.33
                    c0.41-0.23,0.85-0.55,1.32-0.96c0.47-0.41,0.97-0.88,1.49-1.4c-0.64-0.56-1.27-1.13-1.87-1.7c-0.61-0.57-1.22-1.14-1.84-1.73
                    c-0.63-0.58-1.27-1.2-1.93-1.86c-0.63-0.63-1.25-1.27-1.86-1.93c-0.61-0.65-1.2-1.28-1.77-1.89C48.15,7.22,47.6,6.61,47.07,6
                    c-0.52,0.52-0.99,1.02-1.4,1.49c-0.41,0.47-0.73,0.91-0.96,1.32c0.78,0.85,1.23,1.67,1.35,2.44c0.13,0.77-0.07,1.61-0.62,2.51
                    c-0.54,0.91-1.5,2.04-2.86,3.4L32,27.75L21.41,17.17c-1.36-1.36-2.32-2.5-2.86-3.4c-0.54-0.9-0.74-1.75-0.59-2.54
                    c0.14-0.79,0.59-1.6,1.33-2.42c-0.23-0.41-0.55-0.85-0.96-1.32c-0.41-0.47-0.88-0.97-1.4-1.49c-0.56,0.64-1.13,1.27-1.7,1.87
                    c-0.57,0.61-1.14,1.22-1.73,1.84c-0.58,0.63-1.2,1.27-1.86,1.93c-0.63,0.63-1.27,1.25-1.93,1.86c-0.65,0.61-1.28,1.2-1.89,1.77
                    C7.22,15.85,6.61,16.4,6,16.93c0.52,0.52,1.02,0.99,1.49,1.4c0.47,0.41,0.91,0.73,1.32,0.96c0.85-0.77,1.67-1.23,2.44-1.35
                    c0.77-0.13,1.61,0.07,2.51,0.62c0.91,0.54,2.04,1.5,3.4,2.86L27.75,32L17.17,42.59c-1.32,1.32-2.45,2.27-3.37,2.83
                    c-0.92,0.56-1.76,0.77-2.51,0.62c-0.75-0.15-1.57-0.59-2.47-1.32c-0.41,0.23-0.84,0.54-1.29,0.93C7.07,46.03,6.56,46.51,6,47.07
                    c0.86,0.7,1.8,1.54,2.82,2.51c1.01,0.97,1.96,1.9,2.83,2.77s1.77,1.79,2.68,2.74c0.91,0.95,1.78,1.92,2.6,2.91
                    c0.56-0.56,1.04-1.07,1.43-1.52s0.7-0.88,0.93-1.29c-0.7-0.87-1.13-1.68-1.3-2.45c-0.17-0.77,0.03-1.61,0.59-2.54
                    c0.56-0.93,1.51-2.05,2.83-3.37L32,36.25l10.59,10.59c1.32,1.32,2.27,2.45,2.83,3.37c0.56,0.92,0.77,1.76,0.62,2.51
                    c-0.15,0.75-0.59,1.57-1.32,2.47c0.23,0.41,0.54,0.84,0.93,1.29c0.39,0.45,0.87,0.96,1.43,1.52c0.7-0.86,1.54-1.8,2.51-2.82
                    c0.97-1.01,1.9-1.96,2.77-2.83c0.87-0.87,1.79-1.77,2.74-2.68c0.95-0.91,1.92-1.78,2.91-2.6c-0.56-0.56-1.07-1.04-1.52-1.43
                    S55.59,44.94,55.19,44.71z"/>
            </svg>
        </button>
    );
}

export default ExitModalButton;