import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import AllContacts from "./contacts-block.js"

const defaultQuote = 
<p className="quote">
    Мы всегда получаем информацию из первых рук и сопровождаем все сделки. Доступ к закрытым продажам возможен только после личного знакомства.
</p>

const KoshevickiyPortrait = React.memo( () => {
    return <>
        <StaticImage
            src="../images/koshevickiy.png"
            id="koshevickiyPortrait"
            key="koshevickiyPortrait"
            alt="Фотография Василия Кошевицкого"
            placeholder="none"
            layout="constrained"
            loading="lazy"
            height={600}
            transformOptions={{
                quality: 80,
                outputPixelDensities: [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2],
            }}
        />
    </>
})

const KoshevickiyIntroduction = ({ quote = defaultQuote, objectID }) => {
    
    return (
        <>
            <div id="koshevickiyIntroduction" key="koshevickiyIntroduction" >
                <blockquote>
                    {quote}
                    <figcaption className="figcaption for-quote">
                        Руководитель офиса продаж, <br/> Василий Кошевицкий
                    </figcaption>
                </blockquote>
                <div id="block-photo">
                    <KoshevickiyPortrait />
                </div>
                <div className="figcaption for-photo">
                    Руководитель офиса продаж, <br/> Василий Кошевицкий
                </div>
            </div>
            <AllContacts ID={objectID} />
        </>
    );
}

export default KoshevickiyIntroduction;